<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"><vb-forms-5 /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbForms5 from '@/@vb/widgets/Forms/5'

export default {
  name: 'VbWordpressAdd',
  components: {
    VbForms5,
  },
}
</script>
